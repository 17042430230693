




























import { Component, Vue } from 'vue-property-decorator';
import CarColorPicker from '@/modules/cars/components/car-color-picker.vue';
import CarCalendar from '@/modules/cars/components/car-calendar.vue';
import CarActions from '@/modules/cars/components/car-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import CarRatesTooltip from '@/modules/cars/components/car-rates-tooltip.vue';
import CarTogglePrices from '@/modules/cars/components/car-toggle-prices.vue';
import CarsSwitcher from '@/modules/cars/components/cars-switcher.vue';
import { Inject } from 'inversify-props';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

@Component({
    components: {
        CarColorPicker,
        CarCalendar,
        CarActions,
        PageWrapper,
        CarHeader,
        CarRatesTooltip,
        CarTogglePrices,
        CarsSwitcher,
    },
})
export default class RatesCalendarPage extends Vue {
    @Inject(CarsServiceS) carsService!: CarsService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    get isVans(): boolean {
        return this.carsService.storeState.settings.isVans;
    }

    public onClick(value: boolean) {
        this.carsService.storeState.settings.isVans = value;
    }
}
